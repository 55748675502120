import type { GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import AppLayout from '@/components/AppLayout';
import Loader from '@/components/Loader';


const AppIndex = () => (
  <AppLayout>
    <Loader centered size={60} />
  </AppLayout>
);


export async function getStaticProps({ locale }: GetServerSidePropsContext) {
  return {
    props: {
      ...(await serverSideTranslations(locale!, ['common', 'translation', 'error'])),
    },
  };
}


export default AppIndex;

